/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import GrowthDiscovery from 'bundles/epic/data/defaults/GrowthDiscovery.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  fullStoryBrowseEnabled: boolean;
  enablePendoForGateways: boolean;
  algoliaIndexTypeToIndexNameMap: any;
  enableLOHPFullstory: boolean;
  showEvolveCollection: boolean;
  showEvolveCollectionBrowse: boolean;
  showUnifiedHomeSandbox: boolean;
  showBiggerCTAonXDP: boolean;
  cSerpProductCardDensitySkills: boolean;
  cSerpProductCardDensityRating: boolean;
  showRatingsandReviewsOnLOHPProductCards: boolean;
  showRecentlyUpdatedBadge: boolean;
  enableEnterpriseGenAIHubPages: boolean;
  useStreamlinedMWebXDP: boolean;
  serpFilterVariant: 0 | 1 | 2;
  showNewAutocomplete: boolean;
  showNewAutocompleteDogfooding: boolean;
  useDynamicDegreePathway: boolean;
  simplifiedMegamenuQ12025: 'control' | 'B' | 'C';
  enableUnifiedHomeBffCourseCard: boolean;
  showCPlusCtaOnGenAiHubPage: 'control' | 'a';
  showPathwaysGlobalHoldout2025: boolean;
  showPathwaysNAMER: boolean;
  retriggerNamerOnboarding: boolean;
  enableOnboardingAdvancersHomeComplexCollection: boolean;
  showUpdatedNoResultsPage: boolean;
  showBrowseInSerp: boolean;
  enableSelfServePageFullStory: boolean;
  showNewSERPSuggestedSearch: boolean;
  enableComposablePageHeaderOnXDP: boolean;
  xdpAsADiscoveryTool: 'control' | 'A' | 'B';
  productCardLeftVsRight: 'control' | 'B' | 'C';
  enableGenAiCollection: boolean;
  coachInSearch: 'control' | 'B';
  regional2025MarchPromoGermany: boolean;
  regional2025MarchPromoFrance: boolean;
  regional2025MarchPromoIndia: boolean;
  regional2025MarchPromoIndiaEarlyBird: boolean;
  regional2025MarchPromoSpain: boolean;
  regional2025MarchPromoEmergingMarkets: boolean;
  regional2025MarchPromoGlobal: boolean;
  regional2025MarchPromoLatam: boolean;
  showGoogleAISpotlightCollectionsInSearch: boolean;
  opensearchFilteredFacets: boolean;
  enableAiDubbingOnXdp: boolean;
  showLearningProductCard: boolean;
  cmsProxyBffEnabled: boolean;
  enableSelfServeCampaigns: boolean;
};

const NAMESPACE = 'GrowthDiscovery';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([GrowthDiscovery as $TSFixMe]);

const GrowthDiscoveryEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default GrowthDiscoveryEpicClient;
